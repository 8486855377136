<template>
  <v-layout row wrap max-width-1400 full-width ma-auto>
    <v-flex xs12>
      <v-layout row wrap align-center pt-5>
        <v-flex xs12 sm6 :text-xs-right="$vuetify.breakpoint.smAndUp">
          <p
            class="graphik-bold primary--text mb-4"
            :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}"
          >{{ $ml.get('about_cuco_title') }}</p>

          <p class="graphik-medium font-15 dark-grey--text">{{ $ml.get('about_cuco_hobbies') }}</p>
          <p class="graphik-medium font-15 dark-grey--text">{{ $ml.get('about_cuco_location') }}</p>

          <social-media class="text-xs-right" :class="{'pb-4': $vuetify.breakpoint.xsOnly}" />
        </v-flex>

        <v-flex xs12 sm6>
          <v-img
            max-width="500px"
            contain
            class="ma-auto"
            position="center bottom"
            :src="image"
            :lazy-src="image"
          ></v-img>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'ContentAboutKnowCuco',
  data () {
    return {
      image: require('@/assets/img/images/cuco_profile.png')
    }
  },
  components: {
    'social-media': () => import(/* webpackPrefetch: true */ '../components/SocialMedia')
  }
}
</script>

<style scoped>
</style>
